import { getCurrentPageName } from '@ms/yammer-telemetry-support';

import { PerformanceEvent } from '../../events';

import { getOperationNameFromServerTiming, getRequestIdFromServerTiming, getResourceEntryType } from './resourceParser';

const normalizeProperties = (properties: Record<string, string | number>): Record<string, string | number> =>
  Object.keys(properties).reduce((rounded: Record<string, string | number>, key) => {
    const value = properties[key];
    if (typeof value === 'number') {
      rounded[key] = Math.round(value);
    }

    return rounded;
  }, properties);

const getJavaScriptResourceProperties = ({ name }: PerformanceResourceTiming) => ({
  bundleName: name,
});

const getGraphqlResourceProperties = (resourceEntry: PerformanceResourceTiming) => {
  const { serverTiming } = resourceEntry;

  if (!serverTiming) {
    return {};
  }

  const operationName = getOperationNameFromServerTiming(serverTiming);
  const requestId = getRequestIdFromServerTiming(serverTiming);

  return { operationName, requestId };
};

const getFlightingResourceProperties = (resourceEntry: PerformanceResourceTiming) => {
  const nameWithoutQueryParams = resourceEntry.name.substring(0, resourceEntry.name.indexOf('?'));

  return { name: nameWithoutQueryParams, apiName: 'GetEcsFeatureFlags' };
};

const getResourceSpecificProperties = (resourceEntry: PerformanceResourceTiming) => {
  const resourceType = getResourceEntryType(resourceEntry);

  switch (resourceType) {
    case 'GraphQL':
      return getGraphqlResourceProperties(resourceEntry);
    case 'JavaScript':
      return getJavaScriptResourceProperties(resourceEntry);
    case 'Flighting':
      return getFlightingResourceProperties(resourceEntry);
    default:
      return {};
  }
};

const getResourceTimingProperties = (resourceEntry: PerformanceResourceTiming) => {
  const {
    name,
    initiatorType,
    nextHopProtocol,
    workerStart,
    redirectStart,
    redirectEnd,
    fetchStart,
    domainLookupStart,
    domainLookupEnd,
    connectStart,
    connectEnd,
    secureConnectionStart,
    requestStart,
    responseStart,
    responseEnd,
    encodedBodySize,
    decodedBodySize,
  } = resourceEntry;
  const properties = normalizeProperties({
    name,
    initiatorType,
    nextHopProtocol,
    workerStart,
    redirectStart,
    redirectEnd,
    fetchStart,
    domainLookupStart,
    domainLookupEnd,
    connectStart,
    connectEnd,
    secureConnectionStart,
    requestStart,
    responseStart,
    responseEnd,
    encodedBodySize,
    decodedBodySize,
  });

  return {
    ...properties,
    ...getResourceSpecificProperties(resourceEntry as PerformanceResourceTiming),
    page: getCurrentPageName(),
  };
};

type GetResourcePerformanceEvent = (resourceEntry: PerformanceResourceTiming) => PerformanceEvent;
export const getResourcePerformanceEvent: GetResourcePerformanceEvent = (resourceEntry) => ({
  type: 'Performance',
  name: 'resource_timing',
  duration: resourceEntry.duration,
  startTime: resourceEntry.startTime,
  occurredAt: Date.now().toString(),
  properties: getResourceTimingProperties(resourceEntry),
});
