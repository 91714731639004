import { getTelemetryClientConfig } from '@ms/yammer-telemetry-store';

import { ResourceEntryType } from './types';

const ecsResourcePrefix = 'https://ecs.office.com.yammerseat.yammer.seatsa.myshn.eu/config/v1/YammerClients';
const operationNameServerTimingKey = 'opName';
const requestIdServerTimingKey = 'xReqId';

const getValueFromServerTiming = (serverTiming: readonly PerformanceServerTiming[], name: string) => {
  const record = serverTiming && serverTiming.find((timing) => timing.name === name);

  return record && record.description;
};

type GetOperationNameFromServerTiming = (serverTiming: readonly PerformanceServerTiming[]) => string | undefined;
export const getOperationNameFromServerTiming: GetOperationNameFromServerTiming = (serverTiming) =>
  getValueFromServerTiming(serverTiming, operationNameServerTimingKey);

type GetRequestIdFromServerTiming = (serverTiming: readonly PerformanceServerTiming[]) => string | undefined;
export const getRequestIdFromServerTiming: GetRequestIdFromServerTiming = (serverTiming) =>
  getValueFromServerTiming(serverTiming, requestIdServerTimingKey);

const isGraphqlResourceEntry = ({ name }: PerformanceResourceTiming) =>
  name.startsWith(`${getTelemetryClientConfig().graphqlHost}/graphql`);

const isJavaScriptResourceEntry = ({ name }: PerformanceResourceTiming) => name.endsWith('.js');

const isFlightingResourceEntry = ({ name }: PerformanceResourceTiming) => name.startsWith(ecsResourcePrefix);

type GetResourceEntryType = (resourceEntry: PerformanceResourceTiming) => ResourceEntryType;
export const getResourceEntryType: GetResourceEntryType = (resourceEntry) => {
  if (isGraphqlResourceEntry(resourceEntry)) {
    return 'GraphQL';
  }

  if (isJavaScriptResourceEntry(resourceEntry)) {
    return 'JavaScript';
  }

  if (isFlightingResourceEntry(resourceEntry)) {
    return 'Flighting';
  }

  return 'Unknown';
};
