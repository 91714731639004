import { getClientContext, getTelemetryClientConfig } from '@ms/yammer-telemetry-store';
import { v4 as uuidv4 } from 'uuid';

import { getAuthToken } from './getAuthToken';

/**
 * Fallback token is used to prevent telemetry calls from being routed to yammer canary environment.
 * It is randomly picked from a list of tokens as a load balancing strategy (one token per cell).
 * https://www.yammer.com.yammerseat.yammer.seatsa.myshn.eu/microsoft.com/threads/87078827802624
 */
const getFallbackToken = () => {
  const fallbacks = getTelemetryClientConfig().reportAuthorizationFallbacks;
  const randomIndex = Math.floor(Math.random() * fallbacks.length);

  return `${fallbacks[randomIndex]}-TELEMETRY`;
};

const addHeader = (header: string, value?: string) => (value ? { [header]: value } : undefined);

const addMeetingScopeHeadersIfAvailable = () => {
  const { meetingTenantId, teamsMeetingId } = getClientContext();
  const routingAppId = '1';

  if (!meetingTenantId) {
    return {};
  }

  return {
    'X-Yam-Scope-Type': 'TEAMS_MEETING',
    'X-Yam-TenantId': routingAppId + '.' + meetingTenantId,
    ...addHeader('X-Yam-Scope-Entity-Id', teamsMeetingId),
  };
};

const constructEventHeaders = async (withAuthentication: boolean) => {
  const token = withAuthentication ? await getAuthToken() : getFallbackToken();

  return {
    'X-Request-Id': uuidv4(),
    authorization: `Bearer ${token}`,
    ...addMeetingScopeHeadersIfAvailable(),
    ...(!withAuthentication && { 'Authorization-Receiver': getTelemetryClientConfig().reportAuthorizationReceiver }),
  };
};

export type GetEventHeadersWithAuthentication = () => Promise<Record<string, string>>;
export const getEventHeadersWithAuthentication: GetEventHeadersWithAuthentication = () => constructEventHeaders(true);

export type GetEventHeadersWithoutAuthentication = () => Promise<Record<string, string>>;
export const getEventHeadersWithoutAuthentication: GetEventHeadersWithoutAuthentication = () =>
  constructEventHeaders(false);
